const state = {
  todo: "GGMF",
};

const actions = {};

export default {
  namespaced: true,
  state,
  actions,
};
