import axios from "axios";
import { HOST } from "./constant";

export const fetchProvince = async () => {
  try {
    const result = await axios.get(
      `${HOST}/api/document/provinces.json`
    );
    var value;
    var array = [];
    await result.data.Content.forEach((element) => {
      value = {
        id: element[0],
        name: element[1],
      };
      array.push(value);
    });
    return array;
  } catch (e) {
    console.log(e);
    return null;
  }
};
/**
 *
 * @param {string} province_id
 */
export const fetchAmphoe = async (province_id) => {
  // console.log("เข้าหา Amper ยังไม่ foeEach ", province_id)
  try {
    const result = await axios.post(`${HOST}/register/ajax/amphoe`, {
      _token: "TWigQmW41j2ZUApXKAUzNNYSR5TitqEaHVHPgUkz",
      arg: province_id,
    });
    var value;
    var array = [];
    await result.data.Content.forEach((element) => {
      value = {
        id: element[0],
        name: element[1],
      };
      array.push(value);
    });
    return array;
  } catch (e) {
    console.log(e);
    return null;
  }
};
/**
 *
 * @param {string} province_id
 * @param {number} aumphoe_id
 */
export const fetchTambon = async (province_id, aumphoe_id) => {
  try {
    const result = await axios.post(`${HOST}/register/ajax/tambon`, {
      _token: "TWigQmW41j2ZUApXKAUzNNYSR5TitqEaHVHPgUkz",
      province: province_id,
      arg: aumphoe_id,
    });
    var value;
    var array = [];
    await result.data.Content.forEach((element) => {
      value = {
        id: element[0],
        name: element[1],
      };
      array.push(value);
    });
    return array;
  } catch (e) {
    console.log(e);
    return null;
  }
};
/**
 *
 * @param {string} province_id
 * @param {number} tambon_id
 */
export const fetchPostcode = async (province_id, tambon_id) => {
  try {
    const result = await axios.post(`${HOST}/register/ajax/postcode`, {
      _token: "TWigQmW41j2ZUApXKAUzNNYSR5TitqEaHVHPgUkz",
      province: province_id,
      arg: tambon_id,
    });
    var value;
    var array = [];
    await result.data.Content.forEach((element) => {
      value = {
        id: element[0],
        name: element[1],
      };
      array.push(value);
    });
    return array;
  } catch (e) {
    console.log(e);
    return null;
  }
};
