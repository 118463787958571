import axios from "axios";
import { HOST } from "./constant";

/**
 *
 * @param {string} id_card
 */
export const fetchInfo = async (id_card) => {
  try {
    const result = await axios.get(`${HOST}/api/register/${id_card}`);
    return result.data;
  } catch (e) {
    console.log(e);
  }
};

/**
 * 
 * @param {object} data 
 * @example
 * {
    "id_card": 1401600244011, 
    "namePrefix": "นาย", 
    "nameFirst": "กิตธิศักดิ์", 
    "nameLast": "พิมพ์โนนทอง", 
    "namePrefixE": "Mr", 
    "nameFirstE": "Kittisak",
    "nameLastE": "Pimnonthong",
    "bDate": "1996-09-06", 
    "AD_houseNo": "67", 
    "AD_villageNo": "3", 
    "AD_villageName": "โนนงิ้ว", 
    "AD_roadName": null, 
    "AD_province_id": "040",
    "AD_district_id": "014", 
    "AD_subDistrict_id": "001", 
    "AD_postcode": "40230", 
    "CT_phoneNumber": "0984716201", 
    "CT_facebook": null, 
    "CT_line": null,
    "email": "", 
    "PI_FatherName": "บรรเทิง", 
    "PI_MotherName": "บรรทม",
    "stage": 3
  }
 */
export const saveStage = async (data) => {
  try {
    const result = await axios.post(`${HOST}/api/register/stage`, data);
    return result.data;
  } catch (e) {
    console.log(e);
  }
};

/**
 *
 * @param {string} id_card
 */
export const submit = async (id_card) => {
  try {
    const result = await axios.post(`${HOST}/api/register`, { id_card });
    return result.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

/**
 *
 * @param {string} id_card
 * @param {object} file
 * @param {string} type
 */
export const uploadFile = async (id_card, file, type, onProgress) => {
  try {
    const payload = new FormData();
    payload.append("id_card", id_card);
    payload.append("file", file);
    payload.append("type", type);
    const result = await axios.post(`${HOST}/api/register/file`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: function(progressEvent) {
        return onProgress(
          parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          )
        );
      },
    });
    return result;
  } catch (e) {
    console.log(e);
  }
};
