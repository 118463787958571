<template>
  <div class="page-content">
    <Stepper />
  </div>
</template>

<script>
import Stepper from "@/components/Stepper.vue";

export default {
  name: "Register",
  components: {
    Stepper,
  },
  methods: {

  },
};
</script>

<style scoped>
div.page-content {
  background-image: url('/images/wizard-v3.jpg');
}
</style>
