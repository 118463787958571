import Vue from "vue";
import Vuex from "vuex";
import pathify, { make } from "vuex-pathify";
import todo from "@/store/todo";

const state = {};
const mutations = make.mutations(state);

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [pathify.plugin],
  state,
  mutations,
  modules: { todo }
});
